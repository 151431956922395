import React from 'react';
import { graphql, Link } from 'gatsby';
import { SEO } from 'components';
import { Container, MainTitle, Pill, PillsNavBar } from 'ui';
import { Layout, Hero } from 'components';
import StructuredText from 'components/StructuredText';
import TableOfContent from 'components/TableOfContent';
import styled from 'styled-components';
import { Box, Pills, Stack } from '@tymate/margaret';

const Sections = styled.article`
  > section:nth-child(2n) {
    background: ${({ theme }) => theme.background};
  }
`;

const Section = styled(Box).attrs({
  as: 'section',
  paddingVertical: 6,
})``;

const SpecialtyIcon = styled.img`
  width: 7.5em;
  height: auto;
  max-width: 100%;
  display: block;
`;

const Specialty = ({ data: { datoCmsSpecialty } }) => (
  <>
    <SEO
      title={datoCmsSpecialty?.metaTags?.title || datoCmsSpecialty?.name}
      description={datoCmsSpecialty?.metaTags?.description}
    />

    <Layout
      hero={
        <Hero cover={datoCmsSpecialty.parentRight.cover}>
          <Stack
            direction="column"
            alignX="center"
            alignY="center"
            gutterSize={0.5}
            css={`
              flex: 1;
            `}
          >
            <SpecialtyIcon src={datoCmsSpecialty.parentRight.icon.url} alt="" />
            <MainTitle as="div" variant="light">
              {datoCmsSpecialty.parentRight.name}
            </MainTitle>
          </Stack>

          <PillsNavBar>
            <Pills>
              {(datoCmsSpecialty.parentRight.specialties || []).map(
                ({ slug, name }) => (
                  <Pill key={slug}>
                    <Link to={`../${slug}`} activeClassName="active">
                      {name}
                    </Link>
                  </Pill>
                ),
              )}
            </Pills>
          </PillsNavBar>
        </Hero>
      }
    >
      {datoCmsSpecialty.enableTableOfContents && (
        <TableOfContent data={datoCmsSpecialty} />
      )}

      <Sections>
        {(datoCmsSpecialty.sections || []).map(({ section }, index) => (
          <Section>
            <Container
              size="narrow"
              variant={
                datoCmsSpecialty.enableTableOfContents ? 'withAside' : null
              }
            >
              <StructuredText data={section} id={index} />
            </Container>
          </Section>
        ))}
      </Sections>
    </Layout>
  </>
);

export const query = graphql`
  query($slug: String!) {
    datoCmsSpecialty(slug: { eq: $slug }) {
      id
      slug
      name
      enableTableOfContents
      metaTags {
        title
        description
      }
      sections {
        section {
          value
        }
      }
      parentRight {
        slug
        name
        icon {
          url
        }
        cover {
          gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77)
        }
        specialties {
          name
          slug
        }
      }
    }
  }
`;

export default Specialty;
